@import "solar/Tokens.scss";

.component {
  padding-top: $sol-space-md;
  padding-bottom: $sol-space-xl;

  @media (min-width: $sol-breakpoint-lg) {
    max-width: $sol-modal-box-width-md + $sol-space-2xl;
    margin: 0 auto;
    padding-top: $sol-space-xl;
    padding-right: $sol-space-xl;
    padding-bottom: $sol-space-xl;
    padding-left: $sol-space-xl;
  }
}
