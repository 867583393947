@use "sass:math";

// Colors
$sol-color-black: #0a1200;
$sol-color-white: #ffffff;
$sol-color-off-white: #f7f3e8;
$sol-color-neutral-1: #e0dccd;
$sol-color-neutral-2: #655f56;
$sol-color-sky: #78d6ff;
$sol-color-pink: #ff92da;
$sol-color-orange: #ff4f00;
$sol-color-yellow: #f5c800;
$sol-color-lime: #b4cd00;
$sol-color-navy: #002c47;
$sol-color-purple: #3d1c3f;
$sol-color-brown: #6e2504;
$sol-color-gold: #745300;
$sol-color-forest: #263214;
$sol-color-gray-darkest: $sol-color-black;
$sol-color-gray-darker: rgba($sol-color-black, 0.7);
$sol-color-gray-dark: rgba($sol-color-black, 0.35);
$sol-color-gray: rgba($sol-color-black, 0.15);
$sol-color-gray-light: rgba($sol-color-black, 0.07);
$sol-color-gray-light-alpha: rgba(
  237,
  237,
  237,
  1
); // should probably be done another way
$sol-color-gray-lighter: rgba($sol-color-black, 0.05);
$sol-color-gray-lightest: rgba($sol-color-black, 0.03);

// Fonts
$sol-font-family: "Lettera Text", -apple-system, system-ui, Roboto,
  "Helvetica Neue", sans-serif;
$sol-font-family-mono: "Lettera Mono", $sol-font-family;
$sol-font-family-serif: "ITC Cushing", $sol-font-family;

// Typography
$sol-text-1: 16px;
$sol-text-2: 14px;
$sol-text-3: 12px;
$sol-text-4: 10px;
$sol-title-1-size: 54px;
$sol-title-1-size-responsive: 88px;
$sol-title-2-size: 44px;
$sol-title-2-size-responsive: 62px;
$sol-title-3-size: 34px;
$sol-title-3-size-responsive: 44px;
$sol-title-4-size: 24px;
$sol-title-4-size-responsive: 30px;
$sol-title-5-size: 16px;
$sol-title-5-size-responsive: 20px;
$sol-text-line-sm: 1.2em;
$sol-text-line-base: 1.5em;
$sol-text-line-lead: 1.8em;

// Breakpoints
// rt: breakpoint values are also directly set in react, any changes here should be made everywhere
$sol-breakpoint-xs: 0;
$sol-breakpoint-sm: 640px;
$sol-breakpoint-md: 768px;
$sol-breakpoint-lg: 1024px;
$sol-breakpoint-xl: 1280px;
$sol-breakpoint-2xl: 1500px;

// Spacing
$sol-space-2xs: 5px;
$sol-space-xs: 10px;
$sol-space-sm: 20px;
$sol-space-md: 30px;
$sol-space-lg: 50px;
$sol-space-xl: 70px;
$sol-space-2xl: 100px;
$sol-space-3xl: 150px;

// Grid
$sol-modal-box-min-width: math.div($sol-breakpoint-sm, 2);
$sol-modal-box-max-width: math.div($sol-breakpoint-2xl, 2);
$sol-modal-box-width-md: $sol-modal-box-min-width + $sol-space-3xl;

// Shadows
$sol-shadow-dropdown: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.25));
$sol-shadow-map-point: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.25));

// Z index
$sol-z-input-pair: 1;
$sol-z-input-label: $sol-z-input-pair + 1;
$sol-z-subnav: 30;
$sol-z-map-toggle: 40;
$sol-z-map-popover: $sol-z-map-toggle + 1;
$sol-z-chat: 51;
$sol-z-navbar: 60;
$sol-z-search: 70;
$sol-z-dropdown-menu: 80;
$sol-z-modal-dialog: 90;
$sol-z-modal-backdrop: $sol-z-modal-dialog + 1;
$sol-z-modal-content: $sol-z-modal-dialog + 2;
$sol-z-modal-controls: $sol-z-modal-dialog + 3;
$sol-z-modal-close: $sol-z-modal-dialog + 4;
$sol-z-alert-toast: $sol-z-modal-dialog + 99;
$sol-z-popover: 100;

// Border radius
$sol-border-radius-lg: 40px;
$sol-border-radius-md: 20px;
$sol-border-radius-sm: 5px;
$sol-border-radius-half: 999px;

// Photos
$sol-photo-height: 10em;
$sol-photo-height-responsive: 15em;

// Cards
// rt: hacky, we should probably adjust the other border-radius to this more refined sizing
$sol-card-padding: 8px;
$sol-card-border-radius-sm: 10px;
$sol-card-border-radius-md: 15px;

// Helpers
// rt: hacky, this should be moved to a helpers.scss file
@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

// Export values for js
:export {
  solBreakpointXs: strip-unit($sol-breakpoint-xs);
  solBreakpointSm: strip-unit($sol-breakpoint-sm);
  solBreakpointMd: strip-unit($sol-breakpoint-md);
  solBreakpointLg: strip-unit($sol-breakpoint-lg);
  solBreakpointXl: strip-unit($sol-breakpoint-xl);
  solBreakpoint2Xl: strip-unit($sol-breakpoint-2xl);
}
