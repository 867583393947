@import "solar/Tokens.scss";

.grid {
  @media (min-width: $sol-breakpoint-lg) {
    display: grid;
    grid-template-rows: auto 1fr auto;
    min-height: 100vh;
  }
}

.alignment {
  align-self: center;
}
