@import "solar/Tokens.scss";

.component {
  margin-top: $sol-space-sm;
  padding-top: $sol-space-sm;
  border-top: 1px solid $sol-color-gray-light;

  @media (min-width: $sol-breakpoint-lg) {
    margin-top: $sol-space-md;
    padding-top: $sol-space-md;
  }
}
